import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Col, Container, Row } from "react-bootstrap"

import { RouteComponentProps } from "@reach/router"

import * as styles from "../styles/references.module.css"
import { MDXRenderer } from "gatsby-plugin-mdx"

import ReactWordcloud from 'react-wordcloud';
import { isMobile } from "react-device-detect"

interface ReferencesQueryProps extends RouteComponentProps {
    data: {
        allStrapiReference: {
            nodes: {
                name: string;
                weight: number;
            }[]
        }
        strapiReferencePage: {
            engDescription: {
                data: {
                    childMdx: {
                        body: string
                    }
                }
            }   
        }
    }
}

export default function IndexPage( { data, location } : ReferencesQueryProps ) {
    const ref_list = data.allStrapiReference.nodes.map(n => ({text: n.name, value: n.weight}));
    return (
        <Layout lang="en" pageMeta={{title: "References"}} location={location} defaultLang={"hu"} extra_styles={{justifyContent: 'center'}}>
            <Container>
                <Row>
                    <Col className={styles.col_wrapper}>
                        <h3 className={styles.description}>
                            <MDXRenderer>{data.strapiReferencePage.engDescription.data?.childMdx.body ?? ""}</MDXRenderer>
                        </h3>
                        <ReactWordcloud 
                            words={ref_list}
                            maxWords={150}
                            minSize={isMobile ? [300, 500] : [600, 400]}
                            options={{
                                colors: ['#ffffff'],
                                deterministic: true,
                                rotations: 0,
                                spiral: 'archimedean',
                                scale: 'linear',
                                enableTooltip: false,
                                fontFamily: "'PT Serif', serif",
                                fontSizes: [13, 36],
                                padding: 4,
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}


export const query = graphql`
query ReferenceQueryEn {
    allStrapiReference {
        nodes {
            name
            weight
        }
    }
    strapiReferencePage {
        engDescription {
            data {
                childMdx {
                    body
                }
            }
        }   
    }
}
`